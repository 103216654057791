<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd"/>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 기계분류 -->
          <c-select
            type="search"
            codeGroupCd="HHM_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="machineryClassification"
            label="기계분류"
            v-model="searchParam.machineryClassification"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 기계명 -->
          <c-text
            name="machineryName"
            label="기계명"
            v-model="searchParam.machineryName"
          ></c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- itemNo -->
          <c-text
            name="machineryName"
            label="item번호"
            v-model="searchParam.itemNo"
          ></c-text>
        </div>
      </template>
    </c-search-box>
    <c-card title="유해위험기계기구 배치도" class="cardClassDetailForm" :noMarginPadding="true">
      <template slot="card-button">
        <q-btn-group outline>
          <c-btn label="LBLADD" v-if="editable" icon="add" @btnClicked="onItemClick" />
          <c-btn label="LBLREMOVE" v-if="editable && tempMapDetails.length > 0" icon="remove" @btnClicked="removeClick"/>
          <c-btn 
            v-if="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="data.maps"
            mappingType="POST"
            label="LBLSAVE" 
            icon="save" 
            @beforeAction="saveClick"
            @btnCallback="saveCallback"/>
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <template>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 container" ref="carousel">
            <q-carousel
              v-if="data.maps && data.maps.length > 0"
              class="map-carousel"
              control-type="regular"
              control-color="grey-6"
              animated
              arrows
              v-model="data.slide"
              infinite
              @transition="transition"
            >
              <q-carousel-slide 
                v-for="(map, idx) in data.maps"
                :key="idx"
                :name="idx" 
                :img-src="map.mapSrc"
                >
                <template>
                  <div class="mapTitleDiv" v-text="map.mapName"></div>
                  <div class="q-img__content absolute-full"> 
                    <VueDraggableResizable
                      v-for="(item, idx) in map.maps"
                      :key="idx"
                      ref="markImage"
                      class="mainMarkImage"
                      :resizable="false"
                      :parent="true"
                      :draggable="true"
                      :x="item.locationXcoordinate"
                      :y="item.locationYcoordinate"
                      :w="30" :h="30"
                      :grid="[30, 30]"
                      @dragging="(x, y) => onDrag(item, x, y)"
                    >
                      <q-img :src="getMapSrc(item.machineryClassification)" fit="scale-down"
                        style="bottom: 5px;" :contain="true">
                      </q-img>
                      <div class="markImage-abbreviation-parent"
                        :style="{ 
                          width: '220px', 
                          left: getLeft(item),
                        }">
                        <div class="markImage-abbreviation" 
                          @mouseover="() => { item.isHover = true }"
                          @mouseleave="() => { item.isHover = false }">
                          <transition name="mark-list" tag="div">
                            <div>
                              <q-banner
                                dense 
                                :class="[idx % 2 ===1 ? 'bg-grey-3' : 'bg-grey-1', 'markImage-abbreviation-banner-detail']">
                                <template v-slot:avatar>
                                  <q-icon name="warning" class="text-red" size='xs' />
                                </template>
                                <div class="caption-header">
                                  {{item.machineryName}}
                                </div>
                                <div class="caption-work">
                                  {{ 'itemNo: ' + item.itemNo }}
                                  <br>
                                </div>
                              </q-banner>
                            </div>
                          </transition>
                        </div>
                      </div>
                    </VueDraggableResizable>
                  </div>
                </template>
              </q-carousel-slide>
            </q-carousel>
            <el-empty v-else 
              :image-size="435"
              description="지도에 배치된 유해위험기계기구가 없습니다."></el-empty>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 container">
            <c-table
              ref="table"
              title="유해위험기계기구 목록"
              :isTitle="false"
              selection="multiple"
              :columns="grid.columns"
              :data="tempMapDetails"
              rowKey="hhmHazardousMachineryId"
            >
            </c-table>
          </div>
        </template>
      </template>
    </c-card>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
export default {
  name: 'hazard-equipment-layout',
  components: { VueDraggableResizable },
  props: {
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        machineryClassification: null,
        machineryName: '',
        itemNo: '',
      },
      mapMessage: '',
      mapSrc: require('@/assets/images/no-image.png'),
      editable: true,
      detailUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'machineryName',
            field: 'machineryName',
            label: '기계명',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'itemNo',
            field: 'itemNo',
            label: 'item 번호',
            align: 'center',
            style: 'width:130px',
            sortable: false,
          },
          {
            name: 'machineryClassificationName',
            field: 'machineryClassificationName',
            label: '기계분류',
            align: 'center',
            style: 'width:120PX',
            sortable: false,
          },
          {
            name: "gradeName",
            field: "gradeName",
            label: "등급",
            align: "center",
            style: 'width:50px', 
            sortable: true,
          },
          {
            name: "inspectionCycleName",
            field: "inspectionCycleName",
            label: "검사주기",
            align: "center",
            style: 'width:80px', 
            sortable: true,
          },
          {
            name: "lastInspectionDate",
            field: "lastInspectionDate",
            label: "최근검사일",
            align: "center",
            style: 'width:100px', 
            sortable: true,
          },
        ],
        data: [],
        height: '500px'
      },
      data: {
        slide: 0,
        mapSrc: require('@/assets/images/no-image.png'),
        fullscreen: false,
        maps: [],
      },
      tempMapDetails: [],
      listUrl: '',
      saveUrl: '',
      isSave: false,
      deleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    mapRate() {
      return 0.835
    },
  },
  watch: {
    supWorks: {
      handler: function () {
        this.setItems();
      },
      deep: true,
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.hhm.hazard.hazardMap.list.url;
      this.saveUrl = transactionConfig.sop.hhm.hazard.hazardMap.save.url;
      this.deleteUrl = transactionConfig.sop.hhm.hazard.hazardMap.delete.url;
      this.getList();
    },
    getList() {
      let _height = 575 * this.mapRate
      this.$refs['carousel'].style.setProperty("--map-height", String(_height) + 'px');
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.data.maps = _result.data
        if (this.data.maps && this.data.maps.length > 0) {
          this.tempMapDetails = this.data.maps[this.data.slide].mapDetails;
        } else {
          this.tempMapDetails = [];
        }
        this.$refs['carousel'].style.setProperty("--map-ratio", this.data.maps[0].ratio);
      },);
    },
    transition() {   
      this.tempMapDetails = this.data.maps[this.data.slide].mapDetails;
    },
    onDrag(item, x, y) {
      this.realKey = item.hhmHazardousMachineryId

      this.$_.forEach(this.data.maps[this.data.slide].maps, _item => {
        if (_item.hhmHazardousMachineryId === item.hhmHazardousMachineryId) {
          if (_item.editFlag !== 'C') {
            _item.editFlag = 'U'
            _item.chgUserId = this.$store.getters.user.userId
          }
        }
      })
      this.$set(item, 'locationXcoordinate', x)
      this.$set(item, 'locationYcoordinate', y)
    },
    getWidth() {
      let width = 0;
      return width + 'px'
    },
    getLeft(item) {
      /**
       * 지도의 크기는 800
       */
      let left = 0;
      let nonDetailLocation = item.locationXcoordinate + 210;
      let detailLocation = item.locationXcoordinate + 210 
      let calLocation = nonDetailLocation > detailLocation ? nonDetailLocation : detailLocation
      if (calLocation > 800) {
        left = 800 - calLocation
      }
      return left + 'px'
    },
    getTop(item) {
      /**
       * 지도의 높이는 594
       */
      let top = 29;
      let calLocation = item.locationYcoordinate;
      if (calLocation > 594) {
        top = 594 - calLocation
      }
      return top + 'px'
    },
    onItemClick() {
      this.popupOptions.title = '기계/장비 검색'; // 기계/장비 검색
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/mim/hazardEquipment/hazardEquipmentClassPop.vue'}`);
      this.popupOptions.width = '95%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEquipPopup;
    },
    closeEquipPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          let index = -1;
          this.$_.forEach(this.data.maps, _item => {
            if (index === -1) {
              index = this.$_.findIndex(_item.maps, { hhmHazardousMachineryId: item.hhmHazardousMachineryId })
            }
          })
          if (index === -1) {
            this.data.maps[this.data.slide].maps.push({
              hhmHazardousMachineryId: item.hhmHazardousMachineryId,
              machineryName: item.machineryName,
              machineryClassification: item.machineryClassification,
              itemNo: item.itemNo,
              locationXcoordinate: 0,
              locationYcoordinate: 0,
              sopMapId: this.data.maps[this.data.slide].sopMapId,
              editFlag: 'C',
            })
            this.data.maps[this.data.slide].mapDetails.push({
              hhmHazardousMachineryId: item.hhmHazardousMachineryId,
              machineryName: item.machineryName,
              machineryClassificationName: item.machineryClassificationName,
              itemNo: item.itemNo,
              gradeName: item.gradeName,
              inspectionCycleName: item.inspectionCycleName,
              lastInspectionDate: item.lastInspectionDate,
            })
          }
        })
      }
    },
    getMapSrc(type) {
      switch(type) {
        case 'HTC000005' : return require('@/assets/images/hazardEquip05.jpg') // 고정식 크레인
        case 'HTC000010' : return require('@/assets/images/hazardEquip10.png') // 갠트리크레인
        case 'HTC000015' : return require('@/assets/images/hazardEquip15.jpg') // 산업용 로봇
        case 'HTC000020' : return require('@/assets/images/hazardEquip20.jpg') // 컨베이어
        case 'HTC000030' : case 'HTC000080' : 
          return require('@/assets/images/hazardEquip30.png') // 프레스
        case 'HTC000035' : return require('@/assets/images/hazardEquip35.jpg') // 압력용기
        case 'HTC000040' : return require('@/assets/images/hazardEquip40.jpg') // 곤돌라
        case 'HTC000045' : return require('@/assets/images/hazardEquip45.png') // 국소배기장치
        case 'HTC000050' : return require('@/assets/images/hazardEquip50.jpg') // 원심기
        case 'HTC000055' : case 'HTC000060' : return require('@/assets/images/hazardEquip55.jpg') // 설비
        case 'HTC000065' : return require('@/assets/images/hazardEquip65.jpg') // 롤러기
        case 'HTC000070' : return require('@/assets/images/hazardEquip70.jpg') // 사출성형기
        case 'HTC000075' : return require('@/assets/images/hazardEquip75.jpg') // 고소작업대
        case 'HTC000085' : return require('@/assets/images/hazardEquip85.jpg') // 지브형 크레인
        case 'HTC000025' : case 'HTC000090' : case 'HTC000095' : case 'HTC000100' : 
          return require('@/assets/images/hazardEquip25.png') // 리프트
        case 'HTC999999' : return require('@/assets/images/hazardEquip99.png') // 중장비
      }
    },
    saveClick() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGSAVE', // 저장하시겠습니까?
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$_.forEach(this.data.maps, item => {
            item.regUserId = this.$store.getters.user.userId;
            item.chgUserId = this.$store.getters.user.userId;
          });
          this.isSave = !this.isSave;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
    removeClick() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request(() => {
            this.$_.forEach(selectData, item => {
              this.$_.forEach(this.data.maps, _item => {
                _item.maps = this.$_.reject(_item.maps, {hhmHazardousMachineryId: item.hhmHazardousMachineryId})
                _item.mapDetails = this.$_.reject(_item.mapDetails, {hhmHazardousMachineryId: item.hhmHazardousMachineryId})
              })
            })
            this.tempMapDetails = this.data.maps[this.data.slide].mapDetails;
            window.getApp.$emit('APP_REQUEST_SUCCESS');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
  }
};
</script>
<style lang="sass">
.markImage
  padding: 0px !important
  text-align: center
  font-size: 20px

.q-img__content
  .markImage.active
    border-width: 2px !important
    border-style: dashed !important
    border-color: hsla(0, 95%, 35%, 1) !important
.q-img__content > div
    background: rgba(0,0,0,0) 
// .vdr 
//   border: 3px dashed #000

.mainMarkImage
  padding: 0px !important
  text-align: center
  font-size: 20px
  // height: 30px !important
  position: static
  padding: 16px
  color: blue
  // background: rgba(0,0,0,0)
  .markImage-abbreviation-parent
    // width:240px
    text-align: start
    position: absolute
    .markImage-abbreviation
      // cursor: pointer
      display: inline-block
      // min-width:60px
      // max-width:250px
      width: auto
      border-left: 1px solid #757575
      border-right: 1px solid #757575
      border-top: 1px solid #757575
      border-bottom: 1px solid #757575
      .markImage-abbreviation-banner
        border-right: 1px solid #757575
        display: inline-block
        text-align: center
        min-width:60px
        i
          font-size: 25px
      .markImage-abbreviation-banner.last-banner
        border-right: 0
      .markImage-abbreviation-plus
        display: inline-block
        text-align: center
        min-width:40px
      .markImage-abbreviation-banner-detail
        padding: 1px !important
.caption-work
  font-size: 0.5em
  font-style: italic
  white-space: normal
  color: #555

.caption-header
  font-size: 0.75em
  font-style: italic
  white-space: normal
  color: #555

.map-carousel
  height: var(--map-height) !important
  .q-carousel--padding, .q-carousel__slide
    padding: 0 !important
  .q-carousel__slide
    width: calc(var(--map-ratio) * var(--map-height)) !important
  .q-carousel--padding, .q-carousel__slide
    padding: 0 !important
  .q-carousel__arrow
    transition: all 0.5s
    opacity: 0.2
  .q-carousel__arrow:hover
    transition: all 0.5s
    opacity: 0.8
  .q-carousel__slides-container
    .q-panel.scroll
      overflow: hidden !important

</style>

<style scoped>

.mapTitleDiv {
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 10px;
  background-color: #283046 !important;
  color: #fff;
  padding: 5px 10px;
  font-weight: 700;
  z-index: 2000;
  font-size: 12px;
}
</style>